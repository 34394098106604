import React, { useEffect } from "react";
import HeroImage from "../Images/01.jpg";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import { Parallax } from "react-parallax";
import Tiles from "../Tiles";
const images = require.context("../Images/Works", true);

export default function VisualEffects() {
  const imageList = images.keys().map((image) => images(image));
  const tiles = [];
  for (let index = 0; index < imageList.length; index++) {
    tiles.push({
      id: index,
      imageSrc: imageList[index],
      imageAlt: imageList[index],
    });
  }
  console.log("imageList", tiles);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const insideStyles = {
    padding: 0,
    transform: "translate(0%,0%)",
  };

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div style={{ height: 500 }}>
          <h2
            style={insideStyles}
            className=" absolute bottom-6 right-2 text-white text-xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl font-semibold">
            “If it can be imagined, it can be put on screen”
          </h2>
        </div>
      </Parallax>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left py-16">
          Producing award-winning Visual-Effects is the heart and soul of our
          culture. Using the right technology and bright talent we deliver
          stunning Visual-Effects. Over the years we’ve delivered volumes of
          Visual Effects shots to various films. We’ve many big and blockbuster
          films to our credit and over the period we evolved as a go to resource
          for few filmmakers and major VFX studios.
        </p>
        <div className="borderclass"></div>
      </div>
      <Tiles tiles={tiles} title={"Our Works"} />
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
    </m.div>
  );
}
