import React from "react";
import { Galleria } from "primereact/galleria";
const teamimages = require.context("./Images/Team", true);

export default function Gallery() {
  const teamimageList = teamimages.keys().map((image) => teamimages(image));
  const teamgal = [];
  for (let index = 0; index < teamimageList.length; index++) {
    teamgal.push({
      id: index,
      imageSrc: teamimageList[index],
      imageAlt: teamimageList[index],
    });
  }
  console.log("teamgal", teamgal);

  const itemTemplate = (item) => {
    return (
      <img
        src={item.imageSrc}
        alt={item.imageAlt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.imageSrc}
        alt={item.imageAlt}
        style={{ display: "block" }}
      />
    );
  };

  return (
    <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8">
      <p className=" text-2xl pb-6">
        VFX is not a one-man show, it's a collective effort, creating great VFX
        requires great team.
      </p>
      <Galleria
        value={teamgal}
        numVisible={8}
        circular
        autoPlay
        transitionInterval={3000}
        className=" w-auto"
        showThumbnails={false}
        showItemNavigators
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
    </div>
  );
}
