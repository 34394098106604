import React from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from "./Images/THUNDER STUDIOS_LOGO.png";

export default function Footer() {
  const facebook = () => {
    window.open("https://www.facebook.com/ThunderStudiosIndia/");
  };
  const insta = () => {
    window.open("https://www.instagram.com/thunderstudios.in/");
  };

  return (
    <div className="text-black pt-4 pb-16 w-full h-full font-sans ">
      <div className="flex justify-center text-2xl cursor-pointer mb-7 ">
        <BsFacebook className=" w-16 h-[35px]" onClick={facebook} />
        {/* <BsTwitter className=" w-12" />
        <BsYoutube className="  w-12" /> */}
        <BsInstagram className=" w-16 h-[35px]" onClick={insta} />
      </div>
      <br />
      {/* <div className="flex justify-center text-[14px] cursor-pointer ">
        <Link className=" mx-4 border-b-[1px] border-black font-[brandon-grotesque] tracking-[4.06px] leading-5 font-light">
          PRIVACY POLICY{" "}
        </Link>{" "}
        |{" "}
        <Link className=" mx-4 border-b-[1px] border-black font-[brandon-grotesque] tracking-[4.06px] leading-5 font-light">
          {" "}
          TERMS OF USE
        </Link>
      </div>
      <br />
      <div className="flex justify-center text-[14px] cursor-pointer ">
        <Link className=" mx-4 border-b-[1px] border-black font-[brandon-grotesque] tracking-[4.06px] leading-5 font-light">
          YOUR US STATE PRIVACY RIGHTS{" "}
        </Link>{" "}
        |{" "}
        <Link className=" mx-4 border-b-[1px] border-black font-[brandon-grotesque] tracking-[4.06px] leading-5 font-light">
          {" "}
          CHILDREN’S ONLINE PRIVACY POLICY
        </Link>
      </div>
      <br />
      <div className="flex justify-center text-[14px] cursor-pointer ">
        <Link className=" mx-4 border-b-[1px] border-black font-[brandon-grotesque] tracking-[4.06px] leading-5 font-light">
          INTEREST-BASED ADS
        </Link>
      </div> */}
      <div className="flex justify-center text-[14px] cursor-pointer">
        <Link to="/">
          <img
            className=" h-32 w-auto"
            src={Logo}
            alt="Your Company"
            loading="lazy"
          />
        </Link>
      </div>
      <br />
      <div className="flex justify-center text-[14px] cursor-pointer ">
        <Link className=" mx-4   tracking-[4.06px] leading-5 font-light">
          © 2011-2023 THUNDER STUDIOS
        </Link>
      </div>
    </div>
  );
}
