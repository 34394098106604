import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar";
import "../../App.css";

function RootLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}

export default RootLayout;
