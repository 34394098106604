import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "./Images/THUNDER STUDIOS_LOGO.png";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const openpage = () => {
    window.open("https://liveinthunder.com");
  };

  const mobileclick = (e) => {
    e.preventDefault();
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <nav className="bg-white font-sans text-[#334155] font-semibold">
        <div className="mx-auto max-w-8xl px-4 sm:px-11 lg:px-20 lg:py-3">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center flex-shrink-0">
              <Link to="/">
                <img
                  className=" h-16 w-auto"
                  src={Logo}
                  alt="Your Company"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="hidden min-[820px]:flex">
              <Link
                to="/"
                className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-[1.2rem] font-medium">
                VFX STUDIO
              </Link>
              <Link
                to="/VFXschool"
                className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-[1.2rem] font-medium">
                VFX SCHOOL
              </Link>
              <Link
                to="/About"
                className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-[1.2rem] font-medium">
                ABOUT
              </Link>
              <Link
                to="/Connect"
                className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-[1.2rem] font-medium">
                CONNECT
              </Link>
            </div>
            <div className="min-[820px]:hidden text-2xl">
              <button
                onClick={toggleMobileMenu}
                className="text-black hover:text-gray-600">
                {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
      </nav>
      {/* {isMobileMenuOpen && ( */}
      <div
        className={`md:hidden mobilebar ${
          isMobileMenuOpen ? "visible" : ""
        }   absolute  z-10 min-h-[100vh] `}>
        <div className="px-4 pt-11 pb-3 space-y-1 flex flex-col items-start bg-white min-h-[100vh]  ">
          <div onClick={mobileclick} className=" pt-3">
            <Link
              to="/"
              className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-base font-medium">
              VFX STUDIO
            </Link>
            <Link
              to="/VFXschool"
              className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-base font-medium">
              VFX SCHOOL
            </Link>
            <Link
              to="/About"
              className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-base font-medium">
              ABOUT
            </Link>
            <Link
              to="/Connect"
              className=" text-gray-900 hover:bg-gray-700 hover:text-white block rounded-md px-4 py-2 text-base font-medium">
              CONNECT
            </Link>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Navbar;
