import React, { useState } from "react";
import HeroImage from "../Images/About_Big Image.jpg";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import Img1 from "../Images/CEO/BUJI SIR.avif";
import Img2 from "../Images/CEO/RAMESH_SIR.avif";
import Img3 from "../Images/CEO/VENKAT_SIR.avif";
import { Dialog } from "primereact/dialog";
import { Parallax } from "react-parallax";
import Img from "../Opengallery";
import Gallerys from "../Gallery";
const images = require.context("../Images/InsideTS", true);

export default function About() {
  const imageList = images.keys().map((image) => images(image));
  const tiles = [];
  for (let index = 0; index < imageList.length; index++) {
    tiles.push({
      id: index,
      imageSrc: imageList[index],
      imageAlt: imageList[index],
    });
  }

  const [visible, setVisible] = useState(false);
  const [showstate, setshowstate] = useState({
    header: "VFX Producer",
    description:
      "Dev Babu Gandi (Bujji) having over 18 years of experience in VFX takes care of the business development and manages the company’s finance. He was an excellent paint/prep and matchmove artist and well-versed in the art of compositing in the first phase of his career. Later in 2011 he cofounded THUNDER STUDIOS along with Ramesh Gadiraju. All these years he earned a good reputation with clients and many of the them like his ability to deliver the project on-time and within budget. He continues to stretch his efforts on providing exceptional VFX services to filmmakers and VFX studios.",
  });
  const popupfun = (event) => {
    setVisible(true);
    if (event === "Dev Babu Gandi (Bujji)") {
      const item = {
        header: "VFX Producer",
        description:
          "Dev Babu Gandi (Bujji) having over 18 years of experience in VFX takes care of the business development and manages the company’s finance. He was an excellent paint/prep and matchmove artist and well-versed in the art of compositing in the first phase of his career. Later in 2011 he cofounded THUNDER STUDIOS along with Ramesh Gadiraju. All these years he earned a good reputation with clients and many of the them like his ability to deliver the project on-time and within budget. He continues to stretch his efforts on providing exceptional VFX services to filmmakers and VFX studios.",
      };
      setshowstate(item);
    } else if (event === "Ramesh Gadiraju") {
      console.log("event", "Ramesh Gadiraju");
      const item = {
        header: "VFX Supervisor",
        description:
          "Ramesh began his VFX journey in 2005 and after working as a shoot supervisor and compositing lead for 6 years and mentoring hundreds of VFX-Aspirants across various institutes, in 2011 decided to break free and cofounded THUNDER STUDIOS along with Dev Babu (Bujji). Equipped with ample hands-on experience and immense knowledge from pre-post production process and strong operational discipline, he heads the operations and ensures the work-flow is carried out efficiently and effectively to allow the production to function error-free. In addition he takes care of training division.",
      };
      setshowstate(item);
    } else {
      console.log("event", "Venkat Reddy");
      const item = {
        header: "CG Supervisor",
        description:
          "Having over 18 years of experience in Compositing, and working on over 200 VFX projects both domestic and international, and holding Supervisor position previously from a top-grade post-production house, Venkat Reddy has attained strong expertise in delivering all kinds of VFX projects. He is the show-runner, with his tenacity and project management skills, Venkat plans the production and takes the responsibility of delivering projects as per the clients expectation.",
      };
      setshowstate(item);
    }
  };

  const callouts = [
    {
      name: "Dev Babu Gandi (Bujji)",
      description: "VFX Producer",
      imageSrc: Img1,
      imageAlt:
        "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
      href: "#",
    },
    {
      name: "Ramesh Gadiraju",
      description: "VFX Supervisor",
      imageSrc: Img2,
      imageAlt:
        "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
      href: "#",
    },
    {
      name: "Venkat Reddy",
      description: "CG Supervisor",
      imageSrc: Img3,
      imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
      href: "#",
    },
  ];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div className="h-[250px] md:h-[460px] lg:h-[500px] xl:h-[500px] 2xl:h-[500px] "></div>
      </Parallax>
      <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left  pt-8 text-gray-800 text-3xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl font-semibold">
        OUR STORY
      </p>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pb-16 pt-8">
          Founded in 2011, THUNDER STUDIOS was conceived as a post-production
          company offering high-end Visual-Effects services to Films, TV
          commercials, Corporate Ads and other media platforms. Started with
          just 2 members, now the company has grown to about 50+. Producing
          award-winning Visual-Effects is the heart and soul of our culture.
          Using the right technology and bright talent we deliver stunning
          Visual-Effects. Over the period we also evolved as a go-to resource
          for many filmmakers and VFX studios.
        </p>
        <div className="borderclass"></div>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-16">
            <h2 className="text-4xl  text-gray-900">TEAM</h2>

            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div
                  key={callout.name}
                  className="group relative cursor-pointer"
                  onClick={() => popupfun(callout.name)}>
                  <div className="relative shadow-2xl  w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 h-[36rem] sm:h-[50rem] md:h-[62rem] lg:h-[36rem]">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="h-full w-full "
                      fetchpriority="high"
                      loading="lazy"
                    />
                  </div>
                  <h3 className="mt-6 text-sm text-gray-500">
                    <p>
                      <span className="absolute inset-0" />
                      {callout.description}
                    </p>
                  </h3>
                  <p className="text-base font-semibold text-gray-900">
                    {callout.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center pb-12">
        <div className="borderclass"></div>
      </div>
      {/* <ImageSlider /> */}
      {/* <Tiles tiles={tiles} title={"Inside Thunder Studios"} /> */}
      <Gallerys />
      <Img tiles={tiles} title={"Inside Thunder Studios"} />
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
      <Dialog
        header={showstate.header}
        visible={visible}
        className=" w-[90vw] md:w-[60vw] lg:md:w-[50vw] xl:md:w-[50vw] 2xl:md:w-[50vw] "
        onHide={() => setVisible(false)}>
        <p className="m-0">{showstate.description}</p>
      </Dialog>
    </m.div>
  );
}
