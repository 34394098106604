import React from "react";
import HeroImage from "../Images/Connect_Big Image.jpg";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import { Parallax } from "react-parallax";

export default function Connect() {
  const insideStyles = {
    padding: 20,
    transform: "translate(0%,0%)",
  };
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto]  ">
        <div className="h-[250px] md:h-[460px] lg:h-[500px] xl:h-[500px] 2xl:h-[500px] "></div>
      </Parallax>
      <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pt-12 text-gray-800 text-3xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl font-semibold">
        Let's Connect
      </p>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left py-8">
          THUNDER STUDIOS is incredibly passionate about its work, and we are
          constantly looking for methods to make our team more dynamic and
          innovative. We believe that every member, from the supervisor to the
          administrative support, is critical to the company's success. We'd
          like to hear from you if you're interested in joining us. If you want
          to work with us, please submit your CV and portfolio to
          hr@thunderstudios.in.
        </p>
        <div className="borderclass"></div>
      </div>
      <section className="relative isolate overflow-hidden bg-white px-6 pt-24 pb-8  lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-60 sm:skew-x-[-41deg] w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-80 lg:mr-0 lg:origin-center xl:mr-16 xl:origin-center" />
        <div className=" w-full px-16">
          <h2 className=" text-3xl font-normal">
            Get in touch with us & Let's talk
          </h2>
          <div className=" lg:mt-20 mt-10 space-y-6 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0 ">
            <div className=" group relative p-2">
              <h2 className=" flex text-lg items-center">
                <i
                  className="pi pi-stopwatch px-5"
                  style={{ color: "slateblue" }}></i>
                Office Hours
              </h2>
              <div className=" flex flex-col justify-start items-start pl-[56px]">
                <p className="  pt-5 text-gray-500 text-left">
                  Monday - Saturday <br />
                  10:00 AM - 7:00 PM
                </p>
                {/* <p className="  pt-3 text-gray-500">9:00 AM - 6:00 PM</p> */}
              </div>
            </div>
            <div className=" group relative p-2">
              <h2 className=" flex text-base items-center">
                <i
                  className="pi pi-map-marker px-5"
                  style={{ color: "slateblue" }}></i>
                Our Address
              </h2>
              <div className=" flex flex-col justify-start items-start pl-[56px]">
                <p className="  pt-5 text-gray-500 text-left">
                  Plot:74, Road:9,Parkview Enclave,Jubilee Hills, Hyderabad.
                </p>
                {/* <p className=" pt-3 text-gray-500">Parkview Enclave,</p>
                <p className=" pt-3 text-gray-500">Jubilee Hills, Hyderabad.</p> */}
              </div>
            </div>
            <div className=" group relative p-2">
              <h2 className=" flex text-base items-center">
                <i
                  className="pi pi-phone px-5"
                  style={{ color: "slateblue" }}></i>
                Get in Touch
              </h2>
              <div className=" flex flex-col justify-start items-start pl-[56px]">
                <p className="  pt-5 text-gray-500">040-23550545</p>
              </div>
            </div>
            <div className=" group relative p-2">
              <h2 className=" flex text-base items-center">
                <i
                  className="pi pi-envelope px-5"
                  style={{ color: "slateblue" }}></i>
                Email Address
              </h2>
              <div className=" flex flex-col justify-start items-start pl-[56px]">
                <p className="  pt-5 text-gray-500">info@thunderstudios.in</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
    </m.div>
  );
}
