import React from "react";

export default function SidebySide(props) {
  console.log("props", props.content);
  return (
    <div className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
      <div className=" mx-auto max-w-2xl pt-6  lg:max-w-none  mt-3 space-y-12 ">
        <div className="text-left ">
          <h2 className="text-4xl font-normal text-[#1d1d1d] pb-4">
            {props.content.heading}
          </h2>
          <p className=" text-xl font-normal text-[#1d1d1db3] leading-10">
            {props.content.text}
          </p>
        </div>
        <img
          src={props.content.image}
          alt=""
          fetchpriority="high"
          loading="lazy"
          style={{
            marginBottom: "0px",
            marginTop: "0px",
          }}
        />
      </div>
    </div>
  );
}
