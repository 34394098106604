import React from "react";
import { Link } from "react-router-dom";
import HeroImage from "../Images/HomePage_BigImage.avif";
import { motion as m } from "framer-motion";
import MotionGIF from "../Images/MOTION_GRAPHICS.mp4";
import ProductionGif from "../Images/PRE_PRODUCTION.mp4";
import VFXGif from "../Images/VFX.mp4";
import Footer from "../Footer";
import { Parallax } from "react-parallax";

export default function Home() {
  const callouts = [
    {
      name: "",
      description: "Visual Effects",
      imageSrc: VFXGif,
      imageAlt: "Collection of four insulated travel bottles on wooden shelf.",
      href: "/VisualEffects",
    },
    {
      name: "",
      description: "Pre Production",
      imageSrc: ProductionGif,
      imageAlt:
        "Wood table with porcelain mug, leather journal, brass pen, leather key ring, and a houseplant.",
      href: "/PreProduction",
    },
    {
      name: "",
      description: "Motion Graphics",
      imageSrc: MotionGIF,
      imageAlt:
        "Desk with leather desk pad, walnut desk organizer, wireless keyboard and mouse, and porcelain mug.",
      href: "/MotionGraphics",
    },
  ];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div style={{ height: 500 }}></div>
      </Parallax>
      <div className="bg-white">
        <div className="  px-6 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-10 sm:py-10 lg:max-w-none ">
            <h2 className="text-2xl font-bold text-gray-900">What We Do</h2>

            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <Link
                  key={callout.name}
                  to={callout.href}
                  className="group relative">
                  <div className="relative h-[auto] w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 group-hover:opacity-75 ">
                    <video
                      autoPlay
                      loop
                      muted
                      className="h-full w-full object-fill ">
                      <source src={callout.imageSrc} type="video/mp4" />
                    </video>
                  </div>

                  <p className="text-base font-semibold text-gray-900 pb-7 pt-1">
                    {callout.description}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
    </m.div>
  );
}
