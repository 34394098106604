import React, { useEffect } from "react";
import HeroImage from "../Images/Pre_Production_BIG Image.jpg";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import SidebySideLeft from "../SidebySide_left";
import SidebySideRight from "../Sidebyside_right";
import TraditionalImage from "../Images/Pre_Production_STB.avif";
import VisualImage from "../Images/Pre_Production_VSTB_01.avif";
import ConceptImage from "../Images/Pre_Production_VSTB_02.avif";
import PrevizImage from "../Images/Pre_Production-CA_LD.avif";
import { Parallax } from "react-parallax";

const traditional = {
  image: TraditionalImage,
  heading: "Traditional Storyboard",
  text: "Essentially, storyboarding is when the Director, DOP along with Digital Art team map out each scene in pictures or sketches. It's a way of being able to understand the flow of shots that makeup a scene. Traditional Storyboard just involves outlining basic camera moves and deciding roughly how you plan your shots.",
};

const Visual = {
  image: VisualImage,
  heading: "Visual Storyboard",
  text: "Unlike traditional storyboard, Visual Storyboard  depicts the realistic representation of each scene in your film where we can illustrate environments, character placement, blocking, focal length, mood of the scene and other things as well. It’s easy to get lost during a shoot when the sequence involves lot of elements and especially when you are looking for perfect designing and choreography of shots. With Visual Storyboard the Director, DOP, Production Designer will actually understand the scale of their film, explore the possibilities and identify any complexities like whether the production designer should erect how much of a set, or to decide the time needs to build a tricky set, or if the cinematographer needs specific equipment to create stunning visuals. Visual Storyboard is not only for VFX heavy films, it helps any aspiring filmmaker to communicate his vision which helps him to get his film green lit and not to get his vision derailed right from the start of his project through production, post production and onto the screen.",
};
const Concept = {
  image: ConceptImage,
  image1: PrevizImage,
  heading: "Concept Art & Visual development",
  text: "Are you on a mission to pull off a spectacular visual extravaganza? Then narrate us your story, we quickly turn around with 2D sketches, digital paintings and mood boards to bring in on exactly the right kind of visuals for your story.Our Art team, starts with understanding your story – who your characters are, what the environments are, sets, props, anything surrounding them, how they interact with these elements, and when or where they exist.  Be it mythology, fantasy, sci-fi, periodic, action, or any genre, we help you create the right visuals and everything you have dreamed to truly reflect your vision and set the tone for the rest of your project.",
};
const Previz = {
  // image: PrevizImage,
  heading: "Previsualization",
  text: "Previz is the process of making a stripped down version of your film before actually making it. Depending upon the requirement Previz can be done detailedly or very basically, just enough to get the story across and to communicate the idea to the cast and crew. For complicated scenes and action sequences, previz can help communicate the Director’s vision and certain aspects like character blocking, camera motion, lens perspective, lighting, creative editing, etc can be decided and rendered so the entire team knows what is expected. Previz helps you get organised before you dive into the production process which saves the production time and reduces the budget.",
};

export default function Example() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const insideStyles = {
    padding: 20,
    transform: "translate(0%,0%)",
  };
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div className="h-[250px] md:h-[460px] lg:h-[500px] xl:h-[500px] 2xl:h-[500px]">
          <h2
            style={insideStyles}
            className=" absolute bottom-0 right-0 text-white text-xl md:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl font-semibold">
            “Production is Pre-Production”
          </h2>
        </div>
      </Parallax>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left py-16">
          Pre-production is essential in the filmmaking process, because without
          proper planning, your production could run over time, the film could
          go over budget, or you could find yourself in the editing room with
          missing content. Whether its traditional Storyboard,
          Visual-Storyboard, Concept Arts, Look Development, Previz each of
          these below processes plays a crucial role in the success of your
          project.
        </p>
        <div className="borderclass"></div>
      </div>
      <SidebySideLeft content={traditional} />
      <SidebySideLeft content={Visual} />
      {/* <SidebySideLeft content={Concept} /> */}
      <SidebySideRight content={Concept} />
      <SidebySideLeft content={Previz} />
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>

      <Footer />
    </m.div>
  );
}
