import React from "react";

export default function SidebySide(props) {
  console.log("props", props.content);
  return (
    <div className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-4  ">
      <div className=" mx-auto max-w-2xl pt-4  lg:max-w-none  mt-6 space-y-12 ">
        <div className="text-left ">
          <h2 className="text-3xl font-normal text-[#1d1d1d] pb-4">
            {props.content.heading}
          </h2>
          <p className=" text-xl font-normal text-[#1d1d1db3] leading-10">
            {props.content.text}
          </p>
          <h2 className="text-2xl font-normal text-[#334155] pt-6 pb-2">
            {props.content.Duration}
          </h2>
          <h2 className="text-2xl font-normal text-[#334155] pb-4">
            {props.content.Fee}
          </h2>
        </div>
        <img
          src={props.content.image}
          alt=""
          fetchpriority="high"
          className=" sidebysideimg rounded-lg"
          loading="lazy"
        />
      </div>
    </div>
  );
}
