import React, { useEffect } from "react";
import HeroImage from "../Images/Motion Graphics_Big Image.jpg";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import { Parallax } from "react-parallax";

export default function MotionGraphics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const insideStyles = {
    padding: 20,
    transform: "translate(0%,0%)",
  };
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div className="h-[250px] md:h-[460px] lg:h-[500px] xl:h-[500px] 2xl:h-[500px]"></div>
      </Parallax>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pt-16">
          Though mostly into Visual Effects for films, on the flip side we even
          cater to ed-tech companies by giving end-to-end services like setting
          up Green-matte studio, giving all the Animation, VFX , Motion Graphics
          and other e-learning services using latest technology. Our prime
          clients include IndigoLearn and BYJUS.
        </p>
        <br />
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pb-16 ">
          We create effective Explainer videos or marketing videos for your
          company's product or service. Our 2D & Motion Graphics team is so
          strong and artistic which is fit to undertake any kind of challenging
          and creative work, to create magnificent title animations or 2D
          animations to explain your concepts in a beautiful and understandable
          way.
        </p>
      </div>
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
    </m.div>
  );
}
