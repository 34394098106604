import React from "react";
import HeroImage from "../Images/School/BIG_IMAGE.avif";
import { motion as m } from "framer-motion";
import Footer from "../Footer";
import { Parallax } from "react-parallax";
import Composite from "../Images/School/COMP.avif";
import Composite_pro from "../Images/School/COMP_PRO.avif";
import VFX_img from "../Images/School/3D_FOR_VFX.avif";
import PRO_img from "../Images/School/3D_PRO.avif";
import VFX_pro from "../Images/School/VFX_PRO.avif";
import CoursePrice from "../Course_price";

export default function VFXschool() {
  const COMPOSITING = {
    image: Composite,
    heading: "COMPOSITING",
    text: "This course is best suited for existing Roto/Paint/Jr Compositing artists to expand their capabilities and skills to excel in the area of Compositing. This is an intense training program which covers all the areas of Compositing like Green/Blue Screen keying, Wire/Rig removals, Layers Compositing, Tracking, Color Correction, FX compositing, 3D Compositing, etc. He/She will learn from basics of Compositing to advanced level.",
    Duration: "Duration: 3M",
    Fee: "Fee: 70,800 (Inc of GST)",
  };
  const COMPOSITING_PRO = {
    image: Composite_pro,
    heading: "COMPOSITING PRO",
    text: "This COMPOSITING PROfessional course is best suited for aspirants who want to see themselves as a VFX Compositor. This is a beginners course which introduces basic design and image manipulation concepts and then covers the techniques of Rotoscoping, VFX clean-up and all the complex concepts of Compositing like Green/Blue Screen keying, Wire/Rig removals, Layers Compositing, Tracking, Color Correction, FX compositing, 3D Compositing, etc",
    Duration: "Duration: 5M",
    Fee: "Fee: 1,18,000 (Inc of GST)",
  };

  const VFX = {
    image: VFX_img,
    heading: "3D for VFX",
    text: "This course is best suited for enthusiasts who are fascinated towards 3D objects and animations. This course introduces 3D elements and Animations required to create Visual-Effects for Films. This is a beginners course and at the end of the course he/she can handle tasks such as  Modelling, Texturing, Lighting, Shading, Animation and Dynamics and they will learn how to integrate 3D elements with live-action footage. Artistic skills like drawing, painting, sculpting will be a great advantage to create great stuff and to excel in the career.",
    Duration: "Duration: 5M",
    Fee: "Fee: 1,18,000 (Inc of GST)",
  };
  const PRO = {
    image: PRO_img,
    heading: "3D PRO",
    text: "This 3D PROfessional course is an in-depth training module in Character Modeling, Texturing, Animation, Rigging, Photorealistic Lighting, Cloth and Fluid simulations, MatchMove, etc. This is an advanced module preferable for students who already have 3D skills.",
    Duration: "Duration: 5M",
    Fee: "Fee: 1,18,000 (Inc of GST)",
  };
  const VFX_PRO = {
    image: VFX_pro,
    heading: " VFX PRO",
    text: "This VFX PROfessional course is an intensive program to create complex VFX for Films. This is for aspirants who want to enter the VFX industry as a thorough professional having expertise in both 2D and 3D departments.Everything from basics will be covered. Design principles, basics of Art & Photography, Editing, Motion Graphics, Creating 3D elements and integrating them with Live-action footage, Matchmove and in-depth training in advanced Roto & Paint techniques, complex Green/Blue screen Keying, Digital-Makeup and much more will be covered. The advantage with this course is, at the end of the course, he/she will get a 2-month internship at the THUNDER STUDIOS production division where they get a chance to work on real-time projects.",
    Duration: "Duration: 8M + 2M Internship",
    Fee: "Fee: 1,88,800 (Inc of GST)",
  };
  const insideStyles = {
    padding: 0,
    transform: "translate(0%,0%)",
  };
  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className=" font-sans text-[#334155]">
      <Parallax
        bgImage={HeroImage}
        strength={500}
        className=" parallax_cover lg:h-[100vh] xl:h-[100vh] 2xl:h-[100vh] md:h-[auto] h-[auto] ">
        <div style={{ height: 500 }}>
          <h2
            style={insideStyles}
            className=" absolute bottom-3 right-3 text-white text-xl md:text-3xl lg:text-6xl xl:text-6xl 2xl:text-6xl font-semibold">
            VFX School
          </h2>
        </div>
      </Parallax>
      <div className=" flex justify-center items-center flex-col   text-[21px] font-normal text-gray-500 leading-[1.7em] ">
        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pt-16 pb-3">
          Almost every film/tv series/web content/ads we see nowadays are
          incorporating Visual Effects (VFX) for effective storytelling. Now,
          VFX has become an indispensable part of film making and the VFX
          industry is fast emerging.
        </p>

        <p className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left pt-3 pb-10">
          THUNDER School of Advanced Visual-Effects is the training division of
          THUNDER STUDIOS which is born out of passion to inspire and educate
          VFX-Aspirants and to upskill next-gen VFX-Artists. Our School strives
          to provide exactly the kind of curriculum the VFX industry needs. When
          set side by side to the other institutions our’s is much more focused,
          specialized, technically accomplished and uniquely expressive with the
          project based curriculum and studio-like classroom environment and
          dedicated workstations. Our course structure and the practical
          teaching methods are designed in a way that students will become
          valuable team members right from day one of their job.
        </p>
        <div className="borderclass"></div>
        <div className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-8 ">
          <div className=" mx-auto max-w-2xl pt-12  lg:max-w-none  mt-6 space-y-12 ">
            <div>
              <h2 className="text-5xl text-center font-normal text-[#1d1d1d] pb-8">
                COURSES
              </h2>
              <p className=" text-xl text-left font-normal text-[#1d1d1db3] leading-10">
                Our courses are designed by industry experts to create
                production ready professionals. We’ve one of a kind course
                structure which combines concept oriented training with right
                techniques. While every institution focuses on just technical
                aspects, we focus on developing the student both technically and
                creatively.
              </p>
              <p className=" text-xl text-left font-normal text-[#1d1d1db3] leading-10">
                Our VFX School is committed to provide practical and industry
                relevant training. We do not implement “one-size-fits-all”
                teaching methods here, instead we will monitor each student’s
                interest and skill and help them to gain expertise at their own
                pace, time and capabilities. To prepare the aspirants for an
                exciting career in VFX, we offer job ready courses and help them
                to flaunt their career in a few months with universally
                affordable costs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CoursePrice content={COMPOSITING} />
      <div className="flex justify-center items-center py-10">
        <div className="borderclass"></div>
      </div>
      <CoursePrice content={COMPOSITING_PRO} />
      <div className="flex justify-center items-center py-10">
        <div className="borderclass"></div>
      </div>
      <CoursePrice content={VFX} />
      <div className="flex justify-center items-center py-10">
        <div className="borderclass"></div>
      </div>
      <CoursePrice content={PRO} />
      <div className="flex justify-center items-center py-10">
        <div className="borderclass"></div>
      </div>
      <CoursePrice content={VFX_PRO} />
      <div className="flex justify-center items-center py-10">
        <div className="borderclass"></div>
      </div>
      <div className=" flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
        <div className=" mx-auto max-w-2xl pt-12  lg:max-w-none  mt-6 space-y-12 ">
          <div className="text-left">
            <h2 className="text-4xl  font-normal text-[#1d1d1d] pb-4">
              OUR TEAM
            </h2>
            <p className=" text-xl text-left font-normal text-[#1d1d1db3] leading-10 pb-4">
              We have an exceptional team. Our trainers/instructors are real
              time experienced professionals from the production division of
              THUNDER STUDIOS, every key person here including the student
              counselors are based from industry. So, students who are
              passionate about VFX will get a holistic view and a firm
              understanding of production environment. Our academic team is
              committed to serve the students with utmost honesty and sincerity,
              we never promise Moon, but will groom them to shine as Stars in
              their career.
            </p>
            <h2 className="text-4xl  font-normal text-[#1d1d1d] pt-8 pb-4">
              PLACEMENTS
            </h2>
            <p className=" text-xl font-normal text-[#1d1d1db3] leading-10 pb-8">
              Until recently we have trained several students here who
              approached us randomly to help them get a career in the VFX
              industry and most of them have been absorbed by THUNDER STUDIOS
              where they got a chance to work on many Blockbuster Films.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center py-6">
        <div className="borderclass"></div>
      </div>
      <Footer />
    </m.div>
  );
}
