import React, { useState, useRef } from "react";
import { Galleria } from "primereact/galleria";

export default function CustomContentDemo(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const galleria = useRef(null);

  const itemTemplate = (item) => {
    return (
      <img
        fetchpriority="high"
        src={item.imageSrc}
        alt={item.imageAlt}
        style={{ width: "100%", display: "block" }}
        loading="lazy"
      />
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        fetchpriority="high"
        src={item.imageSrc}
        alt={item.imageAlt}
        style={{ display: "block" }}
        loading="lazy"
      />
    );
  };

  return (
    <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <h2 className=" text-2xl pb-11">{props.title}</h2>
      <Galleria
        ref={galleria}
        value={props.tiles}
        numVisible={7}
        style={{ maxWidth: "850px" }}
        activeIndex={activeIndex}
        onItemChange={(e) => setActiveIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />

      <div className="grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
        {props.tiles &&
          props.tiles.map((image, index) => {
            let imgEl = (
              <img
                src={image.imageAlt}
                alt={image.imageAlt}
                fetchpriority="high"
                style={{ cursor: "pointer" }}
                loading="lazy"
                onClick={() => {
                  setActiveIndex(index);
                  galleria.current.show();
                }}
              />
            );
            return (
              <div
                className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7"
                key={index}>
                {imgEl}
              </div>
            );
          })}
      </div>
    </div>
  );
}
