import * as React from "react";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Connect from "./Components/Pages/Connect";
import MotionGraphics from "./Components/Pages/MotionGraphics";
import PreProduction from "./Components/Pages/PreProduction";
import VisualEffects from "./Components/Pages/VisualEffects";
import VFXschool from "./Components/Pages/VFXschool";
import RootLayout from "./Components/Pages/Root";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/About", element: <About /> },
        { path: "/Connect", element: <Connect /> },
        { path: "/MotionGraphics", element: <MotionGraphics /> },
        { path: "/PreProduction", element: <PreProduction /> },
        { path: "/VisualEffects", element: <VisualEffects /> },
        { path: "/VFXschool", element: <VFXschool /> },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
